function dataApiResults(state = {}, action) {
  switch (action.type) {
    case 'DATA_API_RESULT': {
      // support key === 'xx' or 'xx[0]'
      const { data, keyState, resultId } = action;

      let newData = data;
      let keyInStore = keyState;
      let resultIdKey = `order_fetched_${keyInStore}`;

      if (
        keyState &&
        keyState.indexOf('[') !== -1 &&
        keyState.indexOf(']') !== -1
      ) {
        const tmp = keyState.replace(']', '').split('[');
        const theKey = tmp[0];
        const theIndex = tmp[1];

        const currentData = state[theKey] || [];
        currentData[theIndex] = data;

        keyInStore = theKey;
        newData = currentData.slice(0);
        resultIdKey = `order_fetched_${theIndex}_${theIndex}`;
      }

      if (!resultId) {
        return { ...state, [keyInStore]: newData };
      }

      if (state[resultIdKey] && state[resultIdKey] > resultId) {
        return state;
      }

      return {
        ...state,
        [keyInStore]: newData,
        [resultIdKey]: resultId,
      };
    }
    case 'CLEAR_DATA_API_RESULT_BY_KEY_STATE': {
      const { keyState } = action;

      return { ...state, [keyState]: undefined };
    }
    default: {
      return state;
    }
  }
}

export default dataApiResults;
